<template>
  <a-modal
    title="创建用户权限关联"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="id" v-show="false">
              <a-input v-decorator="['id']" />
            </a-form-item>
            <a-form-item label="公司选择" v-if="model.role===2">
              <a-select v-decorator="['corporation_id']" @change="handleCorporationChange" @select="handleCorporationSelect">
                <a-select-option v-for="(item, index) in uclist" :key="index" :value="item.corporation.id">
                  {{ item.corporation.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="当前权限">
              <a-tag closable @close="delet(item.id)" v-for="item in filterlist" :key="item.id">
                {{ item.permission_group }}
              </a-tag>
            </a-form-item>

            <a-form-item label="权限选择">
              <a-select v-decorator="['permission_group_id']" @change="handlePermissionhange">
                <a-select-option v-for="(item, index) in allPerlist" :key="index" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { user_permission_group_delete, user_permission_group_list } from '@/api/user_permission'
import { user_corporation_list } from '@/api/user_corporation'
import { permission_group_list } from '@/api/permission_group'
import pick from 'lodash.pick'
const fields = ['id', 'corporation_id']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  components: {
    AAutoComplete
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      tip: '',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      uclist: [],
      perlist: [],
      filterlist: [],
      allPerlist: []
    }
  },
  created () {
    console.log(this.model, '我是个 简单')
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    this.$nextTick(() => {
      this.setData()
    })
    this.getInfo()
    this.getuclist()
    this.getAllPermission()
  },
  methods: {
    setData () {
      console.log(this.model, '==================')
      if (this.model.role !== 2) {
        this.model.corporation_id = -1
      }
      this.form.setFieldsValue(pick(this.model, fields))
      // this.form.setFieldsValue('user_id', this.model.id)
    },
    delet (id) {
      console.log(id)
      user_permission_group_delete(id).then((res) => {
        console.log('删除成功------')
      })
    },
    handleCorporationChange (value) {
    },
    handlePermissionhange (value) {
    },
    handleCorporationSelect (value) {
      console.log(value, '我是选中的公司')
      this.filterlist = this.perlist.filter((item) => {
        return item.corporation_id === value
      })
    },
    // 当前权限
    getInfo () {
      user_permission_group_list({ user_id: this.model.id }).then((res) => {
        console.log(res, '--')
        this.perlist = res.data
        if (this.model.role !== 2) {
          this.filterlist = res.data
        }
        if (this.model.role === 2) {
          this.filterlist = []
        }
      })
    },
    // 用户公司关联列表
    getuclist () {
      user_corporation_list({ user_id: this.model.id }).then((res) => {
        console.log(res)
        this.uclist = res.data.entries
      })
    },
    //  获取全部角色权限表单
    getAllPermission () {
      permission_group_list({ site: this.model.role === 2 ? 2 : 1 }).then((res) => {
       console.log(res, '权限组织')
        this.allPerlist = res.data
      })
    }
  }
}
</script>
