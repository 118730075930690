import request from '@/utils/request'

const user_permission_groupApi = {
  user_permission_group_list: '/user/permission_group/',
  user_permission_group_create: '/user/permission_group/',
  user_permission_group_delete: '/user/permission_group/'
}

/**
 * 列表
 */
export function user_permission_group_list (parameter) {
  return request({
    url: user_permission_groupApi.user_permission_group_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function user_permission_group_create (parameter) {
  return request({
    url: user_permission_groupApi.user_permission_group_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function user_permission_group_delete (user_per_id) {
  return request({
    url: user_permission_groupApi.user_permission_group_delete + user_per_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
